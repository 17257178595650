<div class="relative w-full flex min-h-64 md:rounded-3xl background p-8 lg:p-16">
  <div class="flex flex-col space-y-6 md:w-1/2 z-20">
    <h2 class="uppercase text-light text-5xl font-bold">Sign up today</h2>
    <div class="w-48 flex items-center justify-center space-x-2 border rounded-full p-2">
        <mat-icon class="text-light-muted material-icons-round">auto_awesome</mat-icon>
        <span class="text-light">Limited time offer</span>
    </div>
    <span class="text-light-muted text-lg">Get your first 3 months completely free. No obligations. No hidden costs. Cancel any time</span>
    <br/>
    <a href="mailto:info@skybeans.app?subject=Sign%20up%20to%20Skybeans" aria-label="Contact us via email" target="_blank" class="flex items-center justify-center space-x-2 w-48 bg-white uppercase text-dark rounded-full p-2">
      <span class="font-bold">Contact Us</span>
      <mat-icon>arrow_forward</mat-icon>
    </a>
  </div>
  <div class="absolute inset-0 w-full h-full scrim z-10 md:rounded-3xl"></div>
</div>

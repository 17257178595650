import { Component, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatRippleModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Output() onLinkClicked = new EventEmitter<string>();
  @Output() onOpenDrawer = new EventEmitter<void>();

  scrollToSection(id: string): void {
    this.onLinkClicked.emit(id);
  }

  openDrawer(): void {
    this.onOpenDrawer.emit();
  }
}

<div class="flex flex-col items-center h-dvh py-4">
  <div class="flex-1 w-full md:w-5/6 lg:w-2/3 px-4 pb-4">
    <header class="flex items-center space-x-4">
      <img class="w-14 h-14 cursor-pointer"
        [routerLink]="['/']"
        alt="skybeans icon" src="../../../../assets/images/brand/skybeans-icon.webp"/>
      <h1 class="text-xl md:text-3xl font-bold">Frequently Asked Questions</h1>
    </header>

    <div class="w-full p-4"><hr/></div>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex items-center space-x-2">
              <mat-icon class="text-dark-muted scale-75">help_outline</mat-icon>
              <span>How do I delete my account?</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          You can delete your account and all associated data from within the Skybeans app.
          Account deletion is immediate and permanent, your data cannot be recovered afterwards.
        </p>
        <br/>
        <ol>
          <li><span>1. Open the Skybeans app</span></li>
          <li><span class="inline">2. Click on Options <span class="inline-flex align-middle items-center"><mat-icon class="scale-75">more_vert</mat-icon></span> > Settings > Delete Account</span></li>
          <li><span>3. Select a reason for account deletion from the dialog window</span></li>
          <li><span>4. Click Delete</span></li>
        </ol>
        <br/>
        <span class="font-bold">The following will be permanently removed:</span>
        <ol>
          <li><span>1. Any places that you have added</span></li>
          <li><span>2. All points accumulated towards a reward</span></li>
          <li><span>3. Your entire transaction history including points earned and redeemed</span></li>
          <li><span>4. Your authentication credentials including your email address</span></li>
        </ol>

        <br/>
        <span class="font-bold">After your account has been removed:</span>
        <ol>
          <li><span>1. Some of your information might be temporarily present in historical log data in our system</span></li>
          <li><span>2. Your data might also be present in database backups used for disaster recovery</span></li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

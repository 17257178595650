<header class="p-4 w-full items-center justify-between lg:justify-center flex h-24">
  <nav class="hidden lg:flex items-center space-x-4">
    <img class="w-14 h-14 cursor-pointer" alt="skybeans icon" src="../../../../assets/images/brand/skybeans-icon.webp"/>

    <div class="w-8"></div>

    <button (click)="scrollToSection('about')"
      class="rounded-3xl text-lg px-6 py-2 hover:bg-gray-100"
      matRipple>About</button>
    <button (click)="scrollToSection('businesses')"
      class="rounded-3xl text-lg px-6 py-2 hover:bg-gray-100"
      matRipple>Businesses</button>
    <button (click)="scrollToSection('app')"
      class="rounded-3xl text-lg px-6 py-2 hover:bg-gray-100"
      matRipple>App</button>

    <div class="w-8"></div>

    <div class="flex items-center space-x-2">
      <mat-icon class="icon-accent material-icons-round">email</mat-icon>
      <span class="text-lg">info&#64;skybeans.app</span>
    </div>

    <div class="w-8"></div>

    <button (click)="scrollToSection('signup')"
      class="button-gradient-accent uppercase py-2 px-8 rounded-full text-lg"
      matRipple>Sign Up</button>
  </nav>

  <div class="flex lg:hidden">
    <button mat-icon-button (click)="openDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="flex lg:hidden">
    <button (click)="scrollToSection('signup')"
      class="button-gradient-accent uppercase py-2 px-8 rounded-full text-lg"
      matRipple>Sign Up</button>
  </div>
</header>

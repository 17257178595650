import { Directive, Inject, PLATFORM_ID, ElementRef, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appInView]',
  standalone: true
})
export class InViewDirective implements OnInit, OnDestroy {
  private observer: IntersectionObserver | null = null;

  @HostBinding('class.in-view') inView = false;

  constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.inView = true;
            this.observer?.unobserve(this.el.nativeElement);
          }
        });
      });
      this.observer.observe(this.el.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (this.observer) this.observer.disconnect();
  }

}

import { Component, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatRippleModule, RouterModule],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent {
  @Output() onCloseDrawer = new EventEmitter<void>();
  @Output() onLinkClicked = new EventEmitter<string>();

  closeDrawer(): void {
    this.onCloseDrawer.emit();
  }

  scrollToSection(id: string): void {
    this.onCloseDrawer.emit();
    this.onLinkClicked.emit(id);
  }
}

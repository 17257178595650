<div class="w-full h-full flex flex-col space-y-4 items-center md:rounded-3xl banner-background parallax p-8 lg:p-16">
  <div class="flex flex-col min-h-64 md:min-h-96 items-center justify-center space-y-4 fade-in-slide">
    <img src="../../../../assets/images/brand/skybeans-logo-reverse.webp"
      class="h-auto max-h-24"
      alt="skybeans logo reverse"/>
    <h1 class="text-center text-2xl text-light-muted">Loyalty rewards at your fingertips</h1>
  </div>

  <div class="w-full flex flex-col md:flex-row justify-center xl:justify-around items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
    <div class="flex items-center space-x-2 fade-in">
      <mat-icon class="text-light shrink-0 mr-2">check</mat-icon>
      <div class="flex flex-col">
        <span class="text-light font-bold">Quick and easy</span>
        <span class="text-light-muted leading-tight">Get set up in under 5 minutes</span>
      </div>
    </div>

    <div class="flex items-center space-x-2 fade-in">
      <mat-icon class="text-light shrink-0 mr-2">check</mat-icon>
      <div class="flex flex-col">
        <span class="text-light font-bold">Fully customizable</span>
        <span class="text-light-muted leading-tight">Make it yours at no extra cost</span>
      </div>
    </div>

    <div class="flex items-center space-x-2 fade-in">
      <mat-icon class="text-light shrink-0 mr-2">check</mat-icon>
      <div class="flex flex-col">
        <span class="text-light font-bold">Cancel any time</span>
        <span class="text-light-muted leading-tight">No long-term contracts</span>
      </div>
    </div>
  </div>
</div>

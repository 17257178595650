import { Component, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { VERSION } from '../../../../version';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [MatIconModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  @Output() onLinkClicked = new EventEmitter<string>();

  isProduction: boolean = environment.isProduction;
  version: string = VERSION;
  currentYear = new Date().getFullYear();

  scrollToSection(id: string): void {
    this.onLinkClicked.emit(id);
  }
}

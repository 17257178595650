<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <mat-drawer #drawer mode="over">
    <app-sidenav
      (onCloseDrawer)="drawer.close()"
      (onLinkClicked)="main.scrollToSection($event)">
    </app-sidenav>
  </mat-drawer>
  <mat-drawer-content class="bg-white">
    <app-main #main (onToggleDrawer)="drawer.toggle()"></app-main>
  </mat-drawer-content>
</mat-drawer-container>

import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-join-now',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './join-now.component.html',
  styleUrl: './join-now.component.scss'
})
export class JoinNowComponent {

}

<div class="relative w-full flex flex-col items-center space-y-6 h-full pb-8 px-8">
  <div class="absolute top-4 right-2">
    <button mat-icon-button (click)="closeDrawer()" [autofocus]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <img src="../../../../assets/images/brand/skybeans-logo-full.webp" class="w-full max-w-96 px-8" alt="Skybeans logo full"/>

  <hr class="w-full"/>

  <button (click)="scrollToSection('about')" matRipple class="w-full flex items-center space-x-3 px-4 py-2 rounded-3xl">
    <mat-icon class="icon-accent material-icons-round">thumb_up</mat-icon>
    <span class="text-xl">About Us</span>
    <div class="grow"></div>
    <mat-icon class="material-icons-round">arrow_forwards</mat-icon>
  </button>

  <button (click)="scrollToSection('businesses')" matRipple class="w-full flex items-center space-x-3 px-4 py-2 rounded-3xl">
    <mat-icon class="icon-accent material-icons-round">business</mat-icon>
    <span class="text-xl">For Businesses</span>
    <div class="grow"></div>
    <mat-icon class="material-icons-round">arrow_forwards</mat-icon>
  </button>

  <button (click)="scrollToSection('app')" matRipple class="w-full flex items-center space-x-3 px-4 py-2 rounded-3xl">
    <mat-icon class="icon-accent material-icons-round">phone_android</mat-icon>
    <span class="text-xl">About the App</span>
    <div class="grow"></div>
    <mat-icon class="material-icons-round">arrow_forwards</mat-icon>
  </button>

  <button [routerLink]="['/faq']" matRipple class="w-full flex items-center space-x-3 px-4 py-2 rounded-3xl">
    <mat-icon class="icon-accent material-icons-round">contact_support</mat-icon>
    <span class="text-xl">FAQ</span>
    <div class="grow"></div>
    <mat-icon class="material-icons-round">arrow_forwards</mat-icon>
  </button>

  <div class="grow"></div>

  <div class="flex items-center space-x-2">
    <mat-icon class="icon-accent material-icons-round">email</mat-icon>
    <span class="text-lg">info&#64;skybeans.app</span>
  </div>

  <hr class="w-full"/>

  <button (click)="scrollToSection('signup')"
    class="w-full button-gradient-accent uppercase py-2 px-8 rounded-full text-lg"
    matRipple>Sign Up</button>

</div>
